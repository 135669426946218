import { i18n } from '../../plugins/i18n';

export default {
  path: 'payment-request',
  component: () =>
    import(
      /* webpackChunkName: "PaymentRequest" */ '../../views/dashboard/payment_request/PaymentRequest.vue'
    ),
  name: 'payment-request',
  meta: {
    page_title: 'Payment request',
    is_wrapper: true,
  },
  children: [
    {
      path: 'create',
      name: 'payment-request-create',
      component: () =>
        import(
          /* webpackChunkName: "PaymentRequestCreate" */ '../../views/dashboard/payment_request/PaymentRequestCreate.vue'
        ),
      meta: {
        page_title: i18n.tc('Create payment request'),
        breadcrumb_items: [
          'general-info',
          'payment-request',
          'payment-request-create',
        ],
      },
    },
    {
      path: 'transactions',
      name: 'payment-request-transactions',
      component: () =>
        import(
          /* webpackChunkName: "PaymentRequestTransactions" */ '../../views/dashboard/payment_request/PaymentRequestTransactions'
        ),
      meta: {
        page_title: i18n.tc('Transactions'),
        plugin: 'payment_request',
        breadcrumb_items: [
          'general-info',
          'payment-request',
          'payment-request-transactions',
        ],
      },
    },
    {
      path: 'transactions/:id',
      name: 'payment-request-transactions-details',
      meta: {
        plugin: 'payment_request',
      },
      component: () =>
        import(
          /* webpackChunkName: "PaymentRequestDetails" */ '../../views/dashboard/payment_request/PaymentRequestDetails'
        ),
    },
    {
      path: 'deleted-transactions',
      name: 'payment-request-deleted',
      component: () =>
        import(
          /* webpackChunkName: "DeletedPrTransactions" */ '../../views/dashboard/payment_request/DeletedPrTransactions'
        ),
      meta: {
        plugin: 'payment_request',
        page_title: i18n.tc('Deleted Paid Transactions'),
        breadcrumb_items: [
          'general-info',
          'payment-request',
          'payment-request-transactions',
        ],
      },
    },
    {
      path: 'table-headers',
      name: 'settings-table-headers',
      component: () =>
        import(
          /* webpackChunkName: "TableHeaders" */ '../../views/dashboard/payment_request/_settings/TableHeaders.js'
        ),
      meta: {
        page_title: i18n.tc('Table Headers'),
        breadcrumb_items: ['general-info', 'payment-request', 'table-headers'],
      },
    },
    {
      path: 'deleted-transactions/:id',
      name: 'payment-request-deleted-details',
      component: () =>
        import(
          /* webpackChunkName: "DeletedPaymentRequestDetails" */ '../../views/dashboard/payment_request/DeletedPaymentRequestDetails'
        ),
      meta: {
        plugin: 'payment_request',
      },
    },
    {
      path: 'invoice',
      name: 'payment-request-create-invoice',
      component: () =>
        import(
          /* webpackChunkName: "InvoiceCreate" */ '../../views/dashboard/payment_request/CreateInvoice'
        ),
      meta: {
        page_title: i18n.tc('Create Invoice'),
        breadcrumb_items: [
          'general-info',
          'payment-request',
          'payment-request-create-invoice',
        ],
      },
    },
    {
      path: 'invoices/:id',
      name: 'payment-request-view-invoice',
      component: () =>
        import(
          /* webpackChunkName: "InvoiceCreate" */ '../../views/dashboard/payment_request/CreateInvoice'
        ),
      meta: {
        page_title: i18n.tc('Invoice'),
        breadcrumb_items: [
          'general-info',
          'payment-request',
          'payment-request-invoice',
        ],
      },
    },
    {
      path: 'deleted-invoices/:id',
      name: 'payment-request-deleted-invoices',
      component: () =>
        import(
          /* webpackChunkName: "InvoiceCreate" */ '../../views/dashboard/payment_request/CreateInvoice'
        ),
      meta: {
        page_title: i18n.tc('Deleted Invoices'),
        breadcrumb_items: [
          'general-info',
          'payment-request',
          'payment-request-deleted-invoices',
        ],
      },
    },
    {
      path: 'global-form-config',
      name: 'payment-request-global-form-config',
      component: () =>
        import(
          /* webpackChunkName: "GlobalFormConfig" */ '../../views/dashboard/payment_request/GlobalFormConfig'
        ),
      meta: {
        page_title: i18n.tc('Global form config'),
        breadcrumb_items: [
          'general-info',
          'payment-request',
          'payment-request-global-form-config',
        ],
      },
    },
  ],
};
